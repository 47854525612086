import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useMoralis } from 'react-moralis';

import { useAuthentication } from 'features/auth/context/AuthContext';
import useChains from 'features/chainSwitching/hooks/useChain';
import RouterComponent from 'routers/index';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcModal from 'shared/NcModal/NcModal';

const App = () => {
  const { chainId } = useMoralis();
  const { validChains } = useAuthentication();

  const [wrongNetworkDialog, setWrongNetworkDialog] = useState(false);

  useEffect(() => {
    if (chainId) {
      if (!validChains?.includes(chainId) || chainId === '0x13881') {
        setWrongNetworkDialog(true);
      } else {
        setWrongNetworkDialog(false);
      }
    }
  }, [chainId]);

  const { switchCustomNetwork } = useChains();

  const renderContent = () => {
    return (
      <div>
        <ButtonPrimary
          sizeClass='px-4 py-2 sm:px-5'
          onClick={() => switchCustomNetwork(validChains[0])}
        >
          Switch Chain
        </ButtonPrimary>
      </div>
    );
  };
  return (
    <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
      <Toaster />
      <RouterComponent />
      <NcModal
        isOpenProp={wrongNetworkDialog}
        onCloseModal={() => {}}
        contentExtraClass='max-w-sm max-h-screen-min'
        contentPaddingClass='flex items-center justify-center py-4 px-10 pb-10 w-full'
        renderContent={renderContent}
        renderTrigger={() => null}
        modalTitle='Wrong Network'
        disableCrossIcon
      />
    </div>
  );
};

export default App;
