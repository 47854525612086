import toast from 'react-hot-toast';
import { useChain, useMoralis } from 'react-moralis';

import { useAuthentication } from 'features/auth/context/AuthContext';
import { networkConfigs } from 'helpers/networks';
import storage from 'utils/storage';

const useChains = () => {
  const { Moralis, isWeb3Enabled, enableWeb3 } = useMoralis();
  const { switchNetwork } = useChain();
  const { web3provider: provider } = useAuthentication();

  async function switchCustomNetwork(chain: string) {
    const web3: any = await Moralis.enableWeb3({ provider });
    if (isWeb3Enabled) {
      if (provider === 'metamask') {
        switchNetwork(chain);
      } else {
        try {
          await web3?.provider?.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chain }],
          });
          storage.setChainId(chain);
        } catch (error: any) {
          try {
            const config = networkConfigs[chain];
            const { chainName, currencyName, currencySymbol, rpcUrl } = config;
            await web3?.provider?.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: chain,
                  chainName,
                  nativeCurrency: {
                    name: currencyName,
                    symbol: currencySymbol,
                    decimals: 18,
                  },
                  rpcUrls: [rpcUrl],
                },
              ],
            });
            storage.setChainId(chain);
          } catch (error: any) {
            toast.error(error.message);
          }
        }
      }
    } else {
      enableWeb3({ provider: 'walletconnect' });
    }
  }
  return { switchCustomNetwork };
};

export default useChains;
